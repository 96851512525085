// Generated by Framer (06534cb)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const serializationHash = "framer-pysZV"

const variantClassNames = {EvN0xpZTG: "framer-v-8rvvzl"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({click, height, id, logo, width, ...props}) => { return {...props, a4fIV94MJ: click ?? props.a4fIV94MJ, NTvT66uwu: logo ?? props.NTvT66uwu ?? {src: "https://framerusercontent.com/images/zBhUdZ17tqUAZhaGbWIyElp5w.png"}} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;logo?: {src: string; srcSet?: string; alt?: string};click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, NTvT66uwu, a4fIV94MJ, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "EvN0xpZTG", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapd6mgcx = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (a4fIV94MJ) {const res = await a4fIV94MJ(...args);
if (res === false) return false;}
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{webPageId: "augiA20Il"}} motionChild nodeId={"EvN0xpZTG"} scopeId={"N2Mf_nYth"}><Image {...restProps} {...gestureHandlers} as={"a"} background={{alt: "", fit: "fill", loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 44, pixelWidth: 120, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(NTvT66uwu), ...{ positionX: "center", positionY: "center" }}} className={`${cx(scopingClassNames, "framer-8rvvzl", className, classNames)} framer-97d45d`} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"EvN0xpZTG"} onTap={onTapd6mgcx} ref={refBinding} style={{...style}}/></Link></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pysZV.framer-97d45d, .framer-pysZV .framer-97d45d { display: block; }", ".framer-pysZV.framer-8rvvzl { cursor: pointer; height: 44px; overflow: visible; position: relative; text-decoration: none; width: 120px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 44
 * @framerIntrinsicWidth 120
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"NTvT66uwu":"logo","a4fIV94MJ":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerN2Mf_nYth: React.ComponentType<Props> = withCSS(Component, css, "framer-pysZV") as typeof Component;
export default FramerN2Mf_nYth;

FramerN2Mf_nYth.displayName = "Helper/Logo";

FramerN2Mf_nYth.defaultProps = {height: 44, width: 120};

addPropertyControls(FramerN2Mf_nYth, {NTvT66uwu: {__defaultAssetReference: "data:framer/asset-reference,zBhUdZ17tqUAZhaGbWIyElp5w.png?originalFilename=Dropie.png&preferredSize=auto", title: "Logo", type: ControlType.ResponsiveImage}, a4fIV94MJ: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerN2Mf_nYth, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})